import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drawer"
export default class extends Controller {
  static targets = [ "backdrop", "panel" ]

  connect() {
    this.close()
  }

  open() {
    this.backdropTarget.classList.remove('hidden')
    this.panelTarget.style.transition = 'transform 0.4s ease-in-out'
    this.panelTarget.style.transform = 'translateX(0)'
    document.body.style.overflow = 'hidden'
  }

  close() {
    this.backdropTarget.classList.add('hidden')
    this.panelTarget.style.transition = 'transform 0.4s ease-in-out'
    this.panelTarget.style.transform = 'translateX(100%)'
    document.body.style.overflow = 'auto'
  }
}
